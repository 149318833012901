
h1 {
  font-size: 2em;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-menu {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}
